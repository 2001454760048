.skillsList {
    list-style-type: none;
}

.skillItem {
    background-color: #494f5c;
    color: whitesmoke;
    border-radius: 20px;
    padding: 2px 12px;
    margin: 1px;
}

.skillBeginner {
    background-color: #494f5c;
}

.skillIntermediate {
    background-color: #494f5c;
}

.skillAdvanced {
    background-color: #494f5c;
}
