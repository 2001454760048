.cvIntro {
    text-align: left;
    padding: 5px 2vw;
    background-color: #282c34;
}

.cvName {
    font-size: calc(25px + 1vmin);
    font-weight: bold;
    color: #FFFFFF;
}

.cvCurrentPosition {
    font-size: calc(12px + 1vmin);
    font-style: italic;
    color: #56595a;
}
