.cvContactInfoListItem {
    font-size: 15px;
}

.cvContactInfoListItem a {
    text-decoration: none;
    color: antiquewhite;
}

.cvContactInfoListItem a:hover {
    color: whitesmoke;
}
