.cvContactInfo {
    padding: 5px 2vw;
    background-color: #494f5c;
}

.cvContactInfoList {
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
