.cvSummary {
    margin: 0;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: calc(10px + 1vmin);
    font-weight: lighter;
    color: #848889;
    text-align: left;
}

.summaryItemTitle {
    font-weight: bold;
    text-decoration: underline;
}