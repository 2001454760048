.resume {
}

.splitScreen {
    display: flex;
    flex-direction: row;
}

.column {
    width: 50%;
    padding: 2px 1vw;
}

.sectionTitle {
    font-size: 20px;
    color: #848889;
    line-height: 1.1;
    text-align: left;
}

.sectionSubtitle {
    font-size: 15px;
    color: #121b36;
    text-align: left;
}

.sectionSubtitleExtraInfo {
    font-size: 13px;
    font-style: italic;
    color: #848889;
    text-align: left;
}

.sectionContent {
    color: #494f5c;
    margin: 0;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 12px;
}
